import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL = window.location.origin + "/api/";

class ConfigurationService {
  getAll(ctx) {
    const url = `process/all?uploadId=${ctx.uploadId}&page=${ctx.currentPage}&size=${ctx.perPage}`;
    return axios.get(BASE_URL + url, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  exportar(ctx) {
    const url = `process/exportar?uploadId=${ctx.uploadId}`;
    return axios
      .get(BASE_URL + url, { headers: authHeader(), responseType: "arraybuffer" })
      .then((response) => {
        return response;
      });
  }

  getData(id) {
    const url = `process/retrive?id=${id}`;
    return axios.get(BASE_URL + url, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }
}

export default new ConfigurationService();

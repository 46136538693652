<template>
  <div class="container wizard">
    <div class="row">
      <div class="col-12 p-0">
        <h2>Envio : {{ upload.name }}</h2>
      </div>
    </div>
    <div class="col-12">
      <b-card>
        <b-card-text>
          <div><b>Archivo : </b>{{ upload.fileName }}</div>
        </b-card-text>
        <b-card-text>
          <div><b>Usuario Creación : </b>{{ upload.createUser }}</div>
        </b-card-text>
        <b-card-text>
          <div><b>Usuario Envio : </b>{{ upload.sendUser }}</div>
        </b-card-text>
        <b-card-text>
          <div><b>Estado : </b>{{ upload.state }}</div>
        </b-card-text>
        <b-card-text>
          <div><b>Corredor : </b>{{ upload.runner }}</div>
        </b-card-text>
      </b-card>
    </div>

    <div class="col-12">
      <b-form-group
        label="Filtro"
        label-for="filter-input"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        content-cols=""
        class="mb-0"
      >
        <b-input-group>
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Tipee lo que desea buscar"
            @keydown="currentPage = 1"
          ></b-form-input>

          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="
                filter = '';
                currentPage = 1;
              "
              >Reset</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>

    <div class="row content">
      <div class="col-12">
        <b-table
          striped
          hover
          :items="items"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filter"
          @filtered="onFiltered"
        >
        </b-table>
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>
    <b-row align-h="end" class="mt-4">
      <b-col md="4" cols="4" style="display: flex; justify-content: flex-end">
        <b-button class="btn-primary a" @click="exportar">Exportar</b-button>
        <!--  
          <b-button class="btn-primary a" @click="reenviar">Reenviar email</b-button>
        -->
        <b-button @click="previous" variant="secondary">Volver</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import processService from "../services/process.service";
import uploadService from "../services/upload.service";

export default {
  data() {
    return {
      filter: "",
      totalRows: 0,
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "date",
          label: "Fc Creación",
          sortable: false,
          formatter: (value) => {
            return value ? value.substring(0, 16).replace("T", " ") : "";
          },
        },
        {
          key: "party",
          label: "Dirección envio",
          sortable: false,
        },
        {
          key: "state",
          label: "Estado",
          sortable: false,
        },
        {
          key: "uniqueId",
          label: "Evicertia ID",
          sortable: false,
        },
        {
          key: "lastSendDate",
          label: "Fecha último envío",
          sortable: false,
          formatter: (value) => {
            return value ? value.substring(0, 16).replace("T", " ") : "";
          },
        },
        {
          key: "sendCount",
          label: "Reenvíos",
          sortable: false,
        },
        { key: "actions", label: "Acciones" },
      ],
      items: [],
      upload: undefined,
    };
  },
  mounted() {
    const uploadId = this.$route.params.id ?? "";
    this.getAll(uploadId);
  },
  methods: {
    getAll(uploadId) {
      var $this = this;

      this.$store.dispatch("app/loading", true);
      uploadService.get(uploadId).then((response) => {
        this.upload = response;
      });

      processService.getAll({ uploadId, perPage: 10, currentPage: 1 }).then((response) => {
        this.items = response;
        this.totalRows = response.length;
        $this.$store.dispatch("app/loading", false);
      });
    },
    exportar() {
      processService.exportar({ uploadId: this.upload.id }).then((response) => {
        const blob = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        link.href = blob;
        link.setAttribute("download", "data.xls");
        document.body.appendChild(link);
        link.click();
      });
    },
    reenviar() {
      this.$store.dispatch("app/loading", true);

      uploadService.resend({ uploadId: this.upload.id }).then((response) => {
        console.log(response);
        if (response.status == 200) {
          this.$store.dispatch("app/loading", false);
          this.$bvToast.toast(
            `Se reenviaron ${response.data.send} / ${response.data.count} 
             procesos a firma, los no enviados son aquellos no vencidos o 
             ya reenviados según la cantidad maxima permitida`,
            {
              title: "Proceso correcto",
              variant: "info",
              solid: true,
            }
          );
          //this.$router.push("/listado.html");
        } else {
          this.$store.dispatch("app/loading", false);

          this.$bvToast.toast(response.data.status, {
            title: "Error en el proceso",
            variant: "error",
            solid: true,
          });
        }
      });
    },
    previous() {
      this.$router.push("/listado.html");
    },
  },
};
</script>

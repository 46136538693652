<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(save)">
        <div class="container wizard">
          <div class="row">
            <div class="col-12 p-0">
              <h2>{{ form.id ? "Edición de usuario" : "Alta de usuario" }}</h2>
            </div>
          </div>

          <div class="row content">
            <!-- PRIMERA COLUMNA -->
            <div class="col-12 col-md-6">
              <!-- NOMBRE -->
              <validation-provider name="nombre" rules="required|max:30" v-slot="validationContext">
                <b-form-group
                  id="example-input-group-1"
                  label-for="userName"
                  label-cols="4"
                  label-cols-lg="3"
                  label="Nombre"
                >
                  <b-form-input
                    id="nombre"
                    type="text"
                    v-model="form.username"
                    :state="getValidationState(validationContext)"
                    class="form-control"
                    aria-describedby="nombre-live-feedback"
                    :readonly="true"
                    tabindex="1"
                  ></b-form-input>
                  <b-form-invalid-feedback id="nombre-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- EMAIL -->
              <validation-provider
                name="email"
                rules="required|max:40|email"
                v-slot="validationContext"
              >
                <b-form-group
                  id="email-input-group"
                  label-for="email"
                  label-cols="4"
                  label-cols-lg="3"
                  label="Email"
                >
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    class="form-control"
                    :state="getValidationState(validationContext)"
                    :readonly="true"
                    aria-describedby="email-live-feedback"
                    tabindex="2"
                  />
                  <b-form-invalid-feedback id="email-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- PASSWORD -->
              <validation-provider
                name="password"
                rules="required|max:20|Upper|Lower|Digit|NonAlphanumeric|confirmed:confirm"
                v-slot="validationContext"
                v-if="form.id === undefined"
              >
                <b-form-group
                  id="password-input-group"
                  label-for="password"
                  label-cols="4"
                  label-cols-lg="3"
                  label="Contraseña"
                >
                  <b-form-input
                    id="password"
                    type="password"
                    v-model="form.password"
                    :state="getValidationState(validationContext)"
                    class="form-control"
                    aria-describedby="password-live-feedback"
                    tabindex="3"
                  />
                  <b-form-invalid-feedback id="password-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- PASSWORD CHECK -->
              <validation-provider
                name="verificación"
                rules="required|max:20"
                v-slot="validationContext"
                vid="confirm"
                v-if="form.id === undefined"
              >
                <b-form-group
                  id="passwordCheck-input-group"
                  label-for="password"
                  label-cols="4"
                  label-cols-lg="3"
                  label="Reingrese Contraseña"
                >
                  <b-form-input
                    id="passwordCheck"
                    type="password"
                    v-model="form.passwordCheck"
                    :state="getValidationState(validationContext)"
                    class="form-control"
                    aria-describedby="passwordCheck-live-feedback"
                    tabindex="4"
                  />
                  <b-form-invalid-feedback id="passwordCheck-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          </div>

          <div class="col-12 btn-container">
            <b-button type="submit" variant="secondary">Guardar</b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import authService from "../../services/auth.service";

export default {
  components: {},

  data() {
    return {
      form: {
        userName: "",
        email: "",
        token: "",
        id: undefined,
      },
    };
  },
  mounted() {
    const email = this.$route.query.email ?? "";
    const token = this.$route.query.token ?? "";

    // const { form } = this.$store.state.users;
    authService.register({ email, token }).then((response) => {
      this.form = response;
      this.form.password = "Carlos2023!";
      this.form.passwordCheck = "Carlos2023!";
    });
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    save() {
      const form = { ...this.form };

      authService.registerConfirm(form).then((response) => {
        if (response.error) {
          this.$bvToast.toast(response.message, {
            title: "Hubo un problema en la registración",
            variant: "error",
            solid: true,
          });
        } else {
          this.$root.$bvToast.toast(response.message, {
            title: "Correcta verificación",
            variant: "success",
            solid: true,
          });

          this.$router.push("/login.html");
        }
      });
    },
  },
  computed: {},
};
</script>

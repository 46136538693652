import axios from "axios";

export default function responseInterceptor(router, store) {
  console.log("init");
  axios.interceptors.response.use(
    function (config) {
      console.log("1", config);
      // Do something before request is sent
      return config;
    },
    function (error) {
      if (error.response.status == 401) {
        store.dispatch("auth/logout").then(() => {
          router.push("/login.html");
        });
      }
      return Promise.reject(error);
    }
  );
}

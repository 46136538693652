<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(save)">
        <div class="container wizard">
          <div class="row">
            <div class="col-12 p-0">
              <h2>{{ "Olvide mi contraseña" }}</h2>
            </div>
          </div>

          <div class="row content">
            <!-- PRIMERA COLUMNA -->
            <div class="col-12 col-md-6">
              <!-- EMAIL -->
              <validation-provider
                name="email"
                rules="required|max:40|email"
                v-slot="validationContext"
              >
                <b-form-group
                  id="email-input-group"
                  label-for="email"
                  label-cols="4"
                  label-cols-lg="3"
                  label="Email"
                >
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    class="form-control"
                    :state="getValidationState(validationContext)"
                    aria-describedby="email-live-feedback"
                    tabindex="1"
                  />
                  <b-form-invalid-feedback id="email-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          </div>

          <div class="col-12 btn-container">
            <b-button type="submit" variant="secondary">Recuperar</b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import authService from "../../services/auth.service";

export default {
  components: {},

  data() {
    return {
      form: {
        email: "",
      },
    };
  },
  mounted() {},
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    save() {
      const form = { ...this.form };

      authService.recovery(form).then((response) => {
        console.log(response);
        if (response.error) {
          this.$bvToast.toast(response.message, {
            title: "Hubo un problema en el proceso",
            variant: "error",
            solid: true,
          });
        } else {
          this.$root.$bvToast.toast(response.message, {
            title: "Correcta verificación",
            variant: "success",
            solid: true,
          });

          this.$router.push("/login.html");
        }
      });
    },
  },
  computed: {},
};
</script>

import Vue from "vue";
import VueRouter from "vue-router";
import Customizacion from "../views/Customizacion.vue";
import IFrame from "../views/IFrame.vue";
import Listado from "../views/Listado.vue";
import Login from "../views/Login.vue";
import Menu from "../views/Menu.vue";
import Process from "../views/Process.vue";
import Reporte from "../views/Reporte.vue";
import UserEdit from "../views/user/Edit.vue";
import UserList from "../views/user/List.vue";
import Recovery from "../views/user/Recovery.vue";
import RecoveryConfirm from "../views/user/RecoveryConfirm.vue";
import Register from "../views/user/Register.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Menu",
    component: Menu,
  },
  {
    path: "/iframe",
    name: "IFrame",
    component: IFrame,
  },
  {
    path: "/login.html",
    name: "Login",
    component: Login,
  },
  {
    path: "/register/:email?/:token?",
    name: "Register",
    component: Register,
  },
  {
    path: "/recovery/:email?/:token?",
    name: "RecoveryConfirm",
    component: RecoveryConfirm,
  },
  {
    path: "/recovery.html",
    name: "Recovery",
    component: Recovery,
  },
  {
    path: "/customizacion.html",
    name: "Customizacion",
    component: Customizacion,
  },
  {
    path: "/user-list.html",
    name: "UserList",
    component: UserList,
  },
  {
    path: "/user-edit.html",
    name: "UserEdit",
    component: UserEdit,
  },
  {
    path: "/listado.html",
    name: "Listado",
    component: Listado,
  },
  {
    path: "/process.html/:id?",
    name: "Procesos",
    component: Process,
  },
  {
    path: "/reporte.html",
    name: "Reporte",
    component: Reporte,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/config",
    name: "Config",
    component: () => import(/* webpackChunkName: "about" */ "../views/Config.vue"),
  },

  /* FORMS COSTA RICA*/

  {
    path: "/form/costarica/:id?",
    name: "FormCostaRica0",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/cr/Form00.vue"),
  },

  {
    path: "/form/costarica-1.html",
    name: "FormCostaRica1",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/cr/Form01.vue"),
  },

  {
    path: "/form/costarica-2.html",
    name: "FormCostaRica2",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/cr/Form02.vue"),
  },

  {
    path: "/form/costarica-3.html",
    name: "FormCostaRica3",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/cr/Form03.vue"),
  },
  {
    path: "/form/costarica-4.html",
    name: "FormCostaRica4",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/cr/Form04.vue"),
  },
  {
    path: "/form/costarica-5.html",
    name: "FormCostaRica5",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/cr/Form05.vue"),
  },
  {
    path: "/form/costarica-6.html",
    name: "FormCostaRica6",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/cr/Form06.vue"),
  },

  /* FORMS EL SALVADOR */

  {
    path: "/form/elsalvador/:id?",
    name: "FormElSalvador0",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/es/Form00.vue"),
  },
  {
    path: "/form/elsalvador-1.html",
    name: "FormElSalvador1",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/es/Form01.vue"),
  },
  {
    path: "/form/elsalvador-2.html",
    name: "FormElSalvador2",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/es/Form02.vue"),
  },
  {
    path: "/form/elsalvador-3.html",
    name: "FormElSalvador3",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/es/Form03.vue"),
  },
  {
    path: "/form/elsalvador-4.html",
    name: "FormElSalvador4",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/es/Form04.vue"),
  },
  {
    path: "/form/elsalvador-5.html",
    name: "FormElSalvador5",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/es/Form05.vue"),
  },

  /* FORMS GUATEMALA */

  {
    path: "/form/guatemala/:id?",
    name: "FormGuatemala0",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/gu/Form00.vue"),
  },
  {
    path: "/form/guatemala-1.html",
    name: "FormGuatemala1",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/gu/Form01.vue"),
  },
  {
    path: "/form/guatemala-2.html",
    name: "FormGuatemala2",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/gu/Form02.vue"),
  },
  {
    path: "/form/guatemala-3.html",
    name: "FormGuatemala3",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/gu/Form03.vue"),
  },
  {
    path: "/form/guatemala-4.html",
    name: "FormGuatemala4",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/gu/Form04.vue"),
  },
  {
    path: "/form/guatemala-5.html",
    name: "FormGuatemala5",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/gu/Form05.vue"),
  },

  /* FORMS HONDURAS */

  {
    path: "/form/honduras/:id?",
    name: "FormHonduras0",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/ho/Form00.vue"),
  },
  {
    path: "/form/honduras-1.html",
    name: "FormHonduras1",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/ho/Form01.vue"),
  },
  {
    path: "/form/honduras-2.html",
    name: "FormHonduras2",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/ho/Form02.vue"),
  },
  {
    path: "/form/honduras-3.html",
    name: "FormHonduras3",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/ho/Form03.vue"),
  },
  {
    path: "/form/honduras-4.html",
    name: "FormHonduras4",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/ho/Form04.vue"),
  },
  {
    path: "/form/honduras-5.html",
    name: "FormHonduras5",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/ho/Form05.vue"),
  },
  {
    path: "/form/honduras-6.html",
    name: "FormHonduras6",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/ho/Form06.vue"),
  },
  {
    path: "/form/honduras-7.html",
    name: "FormHonduras7",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/ho/Form07.vue"),
  },
  {
    path: "/form/honduras-8.html",
    name: "FormHonduras8",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/ho/Form08.vue"),
  },

  /* FORMS PANAMA */

  {
    path: "/form/panama/:id?",
    name: "FormPanama0",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/pa/Form00.vue"),
  },

  {
    path: "/form/panama-1.html",
    name: "FormPanama1",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/pa/Form01.vue"),
  },

  {
    path: "/form/panama-2.html",
    name: "FormPanama2",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/pa/Form02.vue"),
  },

  {
    path: "/form/panama-3.html",
    name: "FormPanama3",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/pa/Form03.vue"),
  },
  {
    path: "/form/panama-4.html",
    name: "FormPanama4",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/pa/Form04.vue"),
  },
  {
    path: "/form/panama-5.html",
    name: "FormPanama5",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/pa/Form05.vue"),
  },
  {
    path: "/form/panama-6.html",
    name: "FormPanama6",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/pa/Form06.vue"),
  },

  /* FORMS ECUADOR */

  {
    path: "/form/ecuador/:id?",
    name: "FormEcuador0",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/ec/Form00.vue"),
  },

  {
    path: "/form/ecuador-1.html",
    name: "FormEcuador1",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/ec/Form01.vue"),
  },

  {
    path: "/form/ecuador-2.html",
    name: "FormEcuador2",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/ec/Form02.vue"),
  },

  {
    path: "/form/ecuador-3.html",
    name: "FormEcuador3",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/ec/Form03.vue"),
  },
  {
    path: "/form/ecuador-4.html",
    name: "FormEcuador4",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/ec/Form04.vue"),
  },
  {
    path: "/form/ecuador-5.html",
    name: "FormEcuador5",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/ec/Form05.vue"),
  },
  {
    path: "/form/ecuador-6.html",
    name: "FormEcuador6",
    component: () => import(/* webpackChunkName: "about" */ "../views/form/ec/Form06.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const formPath = to.fullPath.substring(0, 6);

  if (
    formPath != "/form/" &&
    to.name !== "Recovery" &&
    to.name !== "RecoveryConfirm" &&
    to.name !== "Register" &&
    to.name !== "Login" &&
    to.name !== "IFrame" &&
    !user
  )
    next({ name: "Login" });
  else next();
});

export default router;

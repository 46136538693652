<template>
  <div class="container wizard">
    <div class="row">
      <div class="col-12 p-0">
        <h2>Reporte</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>

<template>
  <div class="container wizard">
    <div class="row">
      <div class="col-12 p-0">
        <h2>Lotes</h2>
      </div>
    </div>

    <div class="col-12">
      <b-form-group
        label="Filtro"
        label-for="filter-input"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        content-cols=""
        class="mb-0"
      >
        <b-input-group>
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            @search="onFilter"
            placeholder="Tipee para buscar por Nombre, Tipo o estado"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
    </div>

    <div class="row content">
      <div class="col-12">
        <b-table
          striped
          hover
          :items="getAll"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :filter-debounce="1000"
          ref="table"
        >
          <template #cell(actions)="row">
            <b-button
              class="btn-primary"
              v-if="row.item.state == 'Generado'"
              :disabled="sendingId != undefined"
              @click="send(row.item, row.index, $event.target)"
              ><b-spinner v-if="isSending(row.item.id)" small></b-spinner
              >{{ isSending(row.item.id) ? "Enviando" : "Enviar" }}</b-button
            >
            <b-button variant="primary" size="sm" @click="detail(row.item, row.index, $event)"
              >Detalle</b-button
            >
            <b-button variant="primary" size="sm" @click="exportarData(row.item, row.index, $event)"
              >Exportar</b-button
            >
            <b-button
              size="sm"
              v-if="isAdmin"
              @click="deleteData(row.item, row.index, $event.target)"
              >Eliminar</b-button
            >
          </template></b-table
        >
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          aria-controls="my-table"
        ></b-pagination>
        <b-row align-h="end" class="mt-4">
          <b-col md="4" cols="4" style="display: flex; justify-content: flex-end">
            <b-button class="btn-primary a" @click="exportar">Exportar</b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import uploadService from "../services/upload.service";

export default {
  data() {
    return {
      isLoading: true,
      sendingId: undefined,
      filter: "",
      totalRows: 0,
      perPage: 5,
      currentPage: 1,
      fields: [
        {
          key: "date",
          label: "Fc Creación",
          sortable: false,
          formatter: (value) => {
            return value ? value.substring(0, 16).replace("T", " ") : "";
          },
        },
        {
          key: "sendDate",
          label: "Fc Envio",
          sortable: false,
          formatter: (value) => {
            return value ? value.substring(0, 16).replace("T", " ") : "";
          },
        },
        {
          key: "type",
          label: "Tipo",
          sortable: false,
        },
        {
          key: "country",
          label: "País",
          sortable: false,
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "count",
          label: "Total",
          sortable: false,
        },
        {
          key: "send",
          label: "Enviados",
          sortable: false,
        },
        {
          key: "signed",
          label: "Firmados",
          sortable: false,
        },
        {
          key: "rejected",
          label: "Rechazados",
          sortable: false,
        },
        {
          key: "state",
          label: "Estado",
          sortable: false,
        },
        { key: "actions", label: "Acciones" },
      ],
    };
  },
  beforeMount() {
    this.filter = this.$store.state.users.filters.uploads;
  },
  methods: {
    isAdmin() {
      return this.$store.state.auth.user.username == "admin";
    },
    onFilter(event) {
      this.filter = event.target.value;
      if (this.currentPage !== 1) this.currentPage = 1;
      else this.$refs.table.refresh();
    },
    getAll(ctx, callback) {
      var $this = this;
      this.$store.dispatch("app/loading", true);

      if (ctx) {
        uploadService
          .getAll({ perPage: ctx.perPage, currentPage: ctx.currentPage - 1, filter: this.filter })
          .then((response) => {
            this.totalRows = parseInt(response.headers["x-total-count"], 10);
            $this.$store.dispatch("app/loading", false);
            callback(response.data);
          });
      }
    },
    send(item) {
      this.sendingId = item.id;
      var $this = this;

      uploadService.send(item.id).then((response) => {
        this.sendingId = undefined;
        console.log(response);

        this.$bvToast.toast(
          `Se enviaron ${response.data.send} de un total ${response.data.count} emails`,
          {
            title: "Proceso correcto",
            variant: "success",
            solid: true,
          }
        );
        $this.$refs.table.refresh();
      });
    },
    detail(item, _, event) {
      if (event.altKey == true) {
        this.check(item);
      } else if (event.shiftKey == true) {
        this.checkAll();
      } else {
        this.$router.push("/process.html/" + item.id);
      }
    },
    exportar() {
      uploadService.exportar({ filter: this.filter }).then((response) => {
        const blob = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/vnd.ms-excel" })
        );
        const link = document.createElement("a");
        link.href = blob;
        link.setAttribute("download", "data.xls");
        document.body.appendChild(link);
        link.click();
      });
    },
    isSending(id) {
      return id == this.sendingId;
    },
    exportarData(item, _, event) {
      if (event.shiftKey == true) {
        uploadService.exportarJSON(item.id).then((response) => {
          console.log(response.data);
        });
      } else {
        uploadService.exportarData(item.id).then((response) => {
          const blob = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/vnd.ms-excel" })
          );

          const link = document.createElement("a");
          link.href = blob;
          link.setAttribute("download", "data.xls");
          document.body.appendChild(link);
          link.click();
        });
      }
    },
    deleteData(item) {
      this.$bvModal
        .msgBoxConfirm(
          "¿ Esta seguro que desea eliminar el proceso, " +
            " el mismo no podra ser recuperado tras la eliminación ?",
          {
            title: "Atención",
            size: "sm",
            buttonSize: "sm",
            okTitle: "Si",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value == true) {
            uploadService.delete(item.id).then(() => {
              this.$refs.table.refresh();
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    check(item) {
      this.sendingId = item.id;
      var $this = this;

      uploadService.check(item.id).then(() => {
        this.sendingId = undefined;
        $this.$refs.table.refresh();
      });
    },
    checkAll() {
      var $this = this;

      uploadService.checkAll().then(() => {
        $this.$refs.table.refresh();
      });
    },
  },

  /*
  computed: {
    rows() {
      return this.items.length;
    },
  },*/
};
</script>

<style scoped></style>

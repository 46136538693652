<template>
  <footer>
    <div class="container fluid">
      <div class="row text-center fixed-bottom">
        <div class="col-12">versión : {{ applicationVersion }}</div>
      </div>
    </div>
  </footer>
</template>

<script>
import { version } from "../../../package.json";

export default {
  data() {
    return {
      applicationVersion: version,
    };
  },
};
</script>

import axios from "axios";

const BASE_URL = window.location.origin + "/auth/";

class AuthService {
  login(user) {
    //    const fakeUser = { username: "deka", password: "", accessToken: "-" };
    //   localStorage.setItem("user", JSON.stringify(fakeUser));

    //return Promise.resolve(fakeUser);

    return axios
      .post(BASE_URL + "login", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }
  logout() {
    localStorage.removeItem("user");
  }

  register(data) {
    return axios
      .get(BASE_URL + "register?email=" + data.email + "&token=" + data.token)
      .then((response) => {
        return response.data;
      });
  }
  registerConfirm(data) {
    return axios.post(BASE_URL + "registerConfirm", data).then((response) => {
      return response.data;
    });
  }

  recovery(data) {
    return axios.post(BASE_URL + "recovery", data).then((response) => {
      return response.data;
    });
  }

  recoveryConfirm(data) {
    return axios.post(BASE_URL + "recoveryConfirm", data).then((response) => {
      return response.data;
    });
  }
}

export default new AuthService();

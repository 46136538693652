<template>
  <div id="app">
    <b-overlay :show="loading" opacity="0.5" color="red" spinner-variant="primary">
      <div>
        <Header></Header>

        <router-view />

        <Footer></Footer>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import Header from "@/components/layout/Header";
import Footer from "@/components/layout/Footer";

export default {
  components: {
    Header,
    Footer,
  },
  mounted: function () {
    //this.$root.style.setProperty("--primary", "#12c931");
    // document.documentElement.style.setProperty("--dynamic-colour", "#12c931");
  },
  computed: {
    loading() {
      return this.$store.state.app.loading;
    },
  },
};
</script>

<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(save)">
        <div class="container wizard">
          <div class="row">
            <div class="col-12 p-0">
              <h2>{{ "Envío de Lote" }}</h2>
            </div>
          </div>

          <div class="row content">
            <!-- PRIMERA COLUMNA -->
            <div class="col-12 col-md-6">
              <!-- ARCHIVO -->
              <validation-provider name="archivo" rules="required" v-slot="{ validate, errors }">
                <b-form-group
                  id="file-input-group-1"
                  label-for="file"
                  label-cols="4"
                  label-cols-lg="3"
                  label="Archivo"
                >
                  <b-form-file
                    :state="Boolean(form.file)"
                    ref="file"
                    accept="xls"
                    @change="handleImage($event) || validate($event)"
                    placeholder="Seleccione un archivo"
                    tabindex="1"
                  ></b-form-file>
                  <b-form-invalid-feedback id="nombre-live-feedback">{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- NOMBRE -->
              <validation-provider name="nombre" rules="required|max:30" v-slot="validationContext">
                <b-form-group
                  id="patrono-input-group"
                  label-for="name"
                  label="Nombre"
                  label-cols="4"
                  label-cols-lg="3"
                >
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    :state="getValidationState(validationContext)"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    aria-describedby="name-live-feedback"
                    tabindex="4"
                  ></b-form-input>
                  <b-form-invalid-feedback id="name-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- CORREDOR -->
              <validation-provider name="corredor" rules="email" v-slot="validationContext">
                <b-form-group
                  id="patrono-input-group"
                  label-for="runner"
                  label="Corredor"
                  label-cols="4"
                  label-cols-lg="3"
                >
                  <b-form-input
                    id="runner"
                    v-model="form.runner"
                    :state="getValidationState(validationContext)"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    aria-describedby="runner-live-feedback"
                    tabindex="5"
                  ></b-form-input>
                  <b-form-invalid-feedback id="runner-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          </div>

          <div class="col-12 btn-container">
            <b-button type="submit" variant="secondary">Procesar</b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import processService from "../services/process.service";
import uploadService from "../services/upload.service";

export default {
  data() {
    return {
      filter: "",
      totalRows: 0,
      perPage: 10,
      currentPage: 1,
      options: {
        types: [
          { value: null, text: "Tipo de envío", disabled: true },
          { value: "EMAIL", text: "EMAIL" },
          { value: "SMS", text: "SMS" },
        ],
      },
      form: {
        file: undefined,
        fileName: "",
        type: "EMAIL",
        runner: "",
      },
      fields: [
        {
          key: "date",
          label: "Fecha",
          sortable: false,
          formatter: (value) => {
            return value ? value.substring(0, 16).replace("T", " ") : "";
          },
        },
        {
          key: "email",
          label: "Email",
          sortable: false,
        },
        {
          key: "uniqueId",
          label: "EvidenceId",
          sortable: false,
        },
        {
          key: "state",
          label: "Estado",
          sortable: false,
        },
      ],
      items: [],
    };
  },
  mounted: function () {
    processService.getAll({ perPage: 10, currentPage: 1 }).then((data) => {
      this.items = data;
      this.totalRows = data.length;
    });
  },
  methods: {
    handleImage(e) {
      const file = e.target.files[0];
      this.form.fileName = file.name;

      const render = new FileReader();
      render.onload = (e) => {
        this.form.file = e.target.result;
      };
      render.readAsDataURL(file);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onFiltered(items) {
      this.totalRows = items.length;
      this.currentPage = 1;
    },
    save() {
      const form = { ...this.form };

      this.$store.dispatch("app/loading", true);

      uploadService.save(form).then((response) => {
        console.log(response);
        if (response.data.statusCode == 200) {
          this.$store.dispatch("app/loading", false);
          this.$bvToast.toast(
            `Se cargaron ${response.data.rowCount} titulares en el proceso de firma`,
            {
              title: "Proceso correcto",
              variant: "success",
              solid: true,
            }
          );
          this.$router.push("/listado.html");
        } else {
          this.$store.dispatch("app/loading", false);

          this.$bvToast.toast(response.data.status, {
            title: "Error en el proceso",
            variant: "error",
            solid: true,
          });
        }
      });
    },
  },
  /*
  computed: {
    rows() {
      return this.items.length;
    },
  },*/
};
</script>

<style scoped></style>

<template>
  <div class="container">
    <div class="row content">
      <iframe
        src="https://ecertia.dekalab.com/Delivery/85fa2834-44e3-439f-8c09-cac8f05d0a2c"
        width="100%"
        height="900px"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

const state = JSON.parse(localStorage.getItem("wizard"));

const initialState = state
  ? state
  : {
      index: 0,
      id: undefined,
      accepted: false,
      form: undefined,
    };

export const wizard = {
  namespaced: true,
  state: initialState,
  actions: {
    save({ commit }, wizard) {
      commit("setWizard", wizard);
      return Promise.resolve(wizard);
    },
    accepted({ commit }, accepted) {
      commit("setAccepted", accepted);
      return Promise.resolve(accepted);
    },
  },
  mutations: {
    setWizard(state, wizard) {
      state.index = wizard.index;
      state.id = wizard.id;
      state.form = { ...state.form, ...wizard.form };
    },
    setAccepted(state, accepted) {
      state.accepted = accepted;
    },
  },
};

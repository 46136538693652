const state = JSON.parse(localStorage.getItem("users"));

const initialState = state
  ? state
  : {
      form: {
        userName: "",
        email: "",
        id: undefined,
      },
      filters: {
        uploads: "",
        process: "",
      },
    };

export const users = {
  namespaced: true,
  state: initialState,
  actions: {
    save({ commit }, user) {
      commit("setuser", user);
      return Promise.resolve(user);
    },
    filterUpload({ commit }, uploads) {
      commit("setFilterUpload", uploads);
      return Promise.resolve(uploads);
    },
  },
  mutations: {
    setuser(state, user) {
      state.form = user;
    },
    setFilterUpload(state, uploads) {
      state.filters.uploads = uploads;
    },
  },
};

import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL = window.location.origin + "/api/";

class UploadService {
  getAll(ctx) {
    const url = `upload?page=${ctx.currentPage}&size=${ctx.perPage}&filter=${ctx.filter}`;
    return axios.get(BASE_URL + url, { headers: authHeader() }).then((response) => {
      return response;
    });
  }
  exportar(ctx) {
    const url = `upload/exportar?filter=${ctx.filter}`;
    return axios
      .get(BASE_URL + url, { headers: authHeader(), responseType: "arraybuffer" })
      .then((response) => {
        return response;
      });
  }
  exportarData(id) {
    const url = `upload/exportarData?id=${id}`;
    return axios
      .get(BASE_URL + url, {
        headers: authHeader(),
        responseType: "arraybuffer",
      })
      .then((response) => {
        return response;
      });
  }

  exportarJSON(id) {
    const url = `upload/exportarJSON?id=${id}`;
    return axios
      .get(BASE_URL + url, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      });
  }

  save(data) {
    return axios.post(BASE_URL + "upload", data, { headers: authHeader() }).then((response) => {
      return response;
    });
  }
  get(id) {
    const url = `upload/get?id=${id}`;
    return axios.get(BASE_URL + url, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  send(id) {
    const url = `upload/send?id=${id}`;
    return axios.get(BASE_URL + url, { headers: authHeader() }).then((response) => {
      return response;
    });
  }

  resend(ctx) {
    const url = `upload/resend?id=${ctx.uploadId}`;
    return axios.get(BASE_URL + url, { headers: authHeader() }).then((response) => {
      return response;
    });
  }

  delete(id) {
    const url = `upload/delete?id=${id}`;
    return axios.get(BASE_URL + url, { headers: authHeader() }).then((response) => {
      return response;
    });
  }

  check(id) {
    const url = `upload/check?id=${id}`;
    return axios.get(BASE_URL + url, { headers: authHeader() }).then((response) => {
      return response;
    });
  }

  checkAll() {
    const url = `upload/checkall`;
    return axios.get(BASE_URL + url, { headers: authHeader() }).then((response) => {
      return response;
    });
  }
}

export default new UploadService();

import Vue from "vue";
import Vuex from "vuex";
import { app } from "./app.module";
import { auth } from "./auth.module";
import { users } from "./users.module";
import { wizard } from "./wizard.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    app,
    wizard,
    users,
  },
});

import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL = window.location.origin + "/api/";

class UserService {
  getAll(ctx) {
    const url = `user?page=${ctx.currentPage}&size=${ctx.perPage}`;
    return axios.get(BASE_URL + url, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  save(data) {
    if (data.id != undefined)
      return axios.put(BASE_URL + "user", data, { headers: authHeader() }).then((response) => {
        return response;
      });
    else
      return axios.post(BASE_URL + "user", data, { headers: authHeader() }).then((response) => {
        return response;
      });
  }

  delete(data) {
    return axios
      .delete(BASE_URL + "user?id=" + data.id, { headers: authHeader() })
      .then((response) => {
        return response;
      });
  }
}

export default new UserService();

const configuration = JSON.parse(localStorage.getItem("app"));

const initialState = configuration
  ? configuration
  : {
      title: "Sin titulo",
      logo: "",
      loading: false,
      titleSecondSection: "",
      titleThirdSection: "",
      bodySecondSection: "",
      bodyThirdSection: "",
    };

export const app = {
  namespaced: true,
  state: initialState,
  actions: {
    setting({ commit }, setting) {
      commit("setSetting", setting);
      return Promise.resolve(setting);
    },
    loading({ commit }, loading) {
      commit("setLoading", loading);
      return Promise.resolve(loading);
    },
  },
  mutations: {
    setSetting(state, setting) {
      state.title = setting.title;
      state.titleSecondSection = setting.titleSecondSection;
      state.titleThirdSection = setting.titleThirdSection;
      state.bodySecondSection = setting.bodySecondSection;
      state.bodyThirdSection = setting.bodyThirdSection;
      state.logo = setting.logo;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
};
